import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { ResetPasswordView } from 'src/sections/reset-password/view';

export default function ResetPasswordPage() {
  const [searchParams]  = useSearchParams();
  const token = searchParams.get('token');

  return (
    <>
      <Helmet>
        <title>Zapizi - Alterar minha senha</title>
      </Helmet>

      <ResetPasswordView token={token ?? ''} />
    </>
  );
}
